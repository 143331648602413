exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogpreview-js": () => import("./../../../src/pages/blogpreview.js" /* webpackChunkName: "component---src-pages-blogpreview-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-hire-2-d-animators-js": () => import("./../../../src/pages/hire-2d-animators.js" /* webpackChunkName: "component---src-pages-hire-2-d-animators-js" */),
  "component---src-pages-hire-2-d-artists-js": () => import("./../../../src/pages/hire-2d-artists.js" /* webpackChunkName: "component---src-pages-hire-2-d-artists-js" */),
  "component---src-pages-hire-3-d-animators-js": () => import("./../../../src/pages/hire-3d-animators.js" /* webpackChunkName: "component---src-pages-hire-3-d-animators-js" */),
  "component---src-pages-hire-3-d-artists-js": () => import("./../../../src/pages/hire-3d-artists.js" /* webpackChunkName: "component---src-pages-hire-3-d-artists-js" */),
  "component---src-pages-hire-apple-vision-pro-developers-js": () => import("./../../../src/pages/hire-apple-vision-pro-developers.js" /* webpackChunkName: "component---src-pages-hire-apple-vision-pro-developers-js" */),
  "component---src-pages-hire-character-designers-js": () => import("./../../../src/pages/hire-character-designers.js" /* webpackChunkName: "component---src-pages-hire-character-designers-js" */),
  "component---src-pages-hire-game-designers-js": () => import("./../../../src/pages/hire-game-designers.js" /* webpackChunkName: "component---src-pages-hire-game-designers-js" */),
  "component---src-pages-hire-game-developers-js": () => import("./../../../src/pages/hire-game-developers.js" /* webpackChunkName: "component---src-pages-hire-game-developers-js" */),
  "component---src-pages-hire-unity-developers-js": () => import("./../../../src/pages/hire-unity-developers.js" /* webpackChunkName: "component---src-pages-hire-unity-developers-js" */),
  "component---src-pages-hire-unreal-engine-game-developers-js": () => import("./../../../src/pages/hire-unreal-engine-game-developers.js" /* webpackChunkName: "component---src-pages-hire-unreal-engine-game-developers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-educational-game-development-js": () => import("./../../../src/pages/industries/educational-game-development.js" /* webpackChunkName: "component---src-pages-industries-educational-game-development-js" */),
  "component---src-pages-integrated-management-system-policy-js": () => import("./../../../src/pages/integrated-management-system-policy.js" /* webpackChunkName: "component---src-pages-integrated-management-system-policy-js" */),
  "component---src-pages-our-work-allie-the-ellie-js": () => import("./../../../src/pages/our-work/allie-the-ellie.js" /* webpackChunkName: "component---src-pages-our-work-allie-the-ellie-js" */),
  "component---src-pages-our-work-avatar-digital-twin-js": () => import("./../../../src/pages/our-work/avatar-digital-twin.js" /* webpackChunkName: "component---src-pages-our-work-avatar-digital-twin-js" */),
  "component---src-pages-our-work-bigstakes-5-js": () => import("./../../../src/pages/our-work/bigstakes5.js" /* webpackChunkName: "component---src-pages-our-work-bigstakes-5-js" */),
  "component---src-pages-our-work-buggy-car-racing-js": () => import("./../../../src/pages/our-work/buggy-car-racing.js" /* webpackChunkName: "component---src-pages-our-work-buggy-car-racing-js" */),
  "component---src-pages-our-work-drb-saudi-nft-club-js": () => import("./../../../src/pages/our-work/drb-saudi-nft-club.js" /* webpackChunkName: "component---src-pages-our-work-drb-saudi-nft-club-js" */),
  "component---src-pages-our-work-farm-simulator-js": () => import("./../../../src/pages/our-work/farm-simulator.js" /* webpackChunkName: "component---src-pages-our-work-farm-simulator-js" */),
  "component---src-pages-our-work-gino-devolution-js": () => import("./../../../src/pages/our-work/gino-devolution.js" /* webpackChunkName: "component---src-pages-our-work-gino-devolution-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-our-work-rage-war-js": () => import("./../../../src/pages/our-work/rage-war.js" /* webpackChunkName: "component---src-pages-our-work-rage-war-js" */),
  "component---src-pages-our-work-shadow-fighter-js": () => import("./../../../src/pages/our-work/shadow-fighter.js" /* webpackChunkName: "component---src-pages-our-work-shadow-fighter-js" */),
  "component---src-pages-our-work-smash-block-blast-js": () => import("./../../../src/pages/our-work/smash-block-blast.js" /* webpackChunkName: "component---src-pages-our-work-smash-block-blast-js" */),
  "component---src-pages-our-work-vegas-crime-city-js": () => import("./../../../src/pages/our-work/vegas-crime-city.js" /* webpackChunkName: "component---src-pages-our-work-vegas-crime-city-js" */),
  "component---src-pages-our-work-vr-nanite-js": () => import("./../../../src/pages/our-work/vr-nanite.js" /* webpackChunkName: "component---src-pages-our-work-vr-nanite-js" */),
  "component---src-pages-our-work-we-cut-trees-js": () => import("./../../../src/pages/our-work/we-cut-trees.js" /* webpackChunkName: "component---src-pages-our-work-we-cut-trees-js" */),
  "component---src-pages-our-work-xdog-js": () => import("./../../../src/pages/our-work/xdog.js" /* webpackChunkName: "component---src-pages-our-work-xdog-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-process-of-game-development-js": () => import("./../../../src/pages/process-of-game-development.js" /* webpackChunkName: "component---src-pages-process-of-game-development-js" */),
  "component---src-pages-services-2-d-animation-services-js": () => import("./../../../src/pages/services/2d-animation-services.js" /* webpackChunkName: "component---src-pages-services-2-d-animation-services-js" */),
  "component---src-pages-services-3-d-game-animation-js": () => import("./../../../src/pages/services/3d-game-animation.js" /* webpackChunkName: "component---src-pages-services-3-d-game-animation-js" */),
  "component---src-pages-services-3-d-game-development-company-js": () => import("./../../../src/pages/services/3d-game-development-company.js" /* webpackChunkName: "component---src-pages-services-3-d-game-development-company-js" */),
  "component---src-pages-services-3-d-game-environment-services-js": () => import("./../../../src/pages/services/3d-game-environment-services.js" /* webpackChunkName: "component---src-pages-services-3-d-game-environment-services-js" */),
  "component---src-pages-services-3-d-game-modeling-js": () => import("./../../../src/pages/services/3d-game-modeling.js" /* webpackChunkName: "component---src-pages-services-3-d-game-modeling-js" */),
  "component---src-pages-services-3-d-sculpting-services-js": () => import("./../../../src/pages/services/3d-sculpting-services.js" /* webpackChunkName: "component---src-pages-services-3-d-sculpting-services-js" */),
  "component---src-pages-services-app-store-optimization-service-js": () => import("./../../../src/pages/services/app-store-optimization-service.js" /* webpackChunkName: "component---src-pages-services-app-store-optimization-service-js" */),
  "component---src-pages-services-augmented-reality-game-development-company-js": () => import("./../../../src/pages/services/augmented-reality-game-development-company.js" /* webpackChunkName: "component---src-pages-services-augmented-reality-game-development-company-js" */),
  "component---src-pages-services-blockchain-game-development-js": () => import("./../../../src/pages/services/blockchain-game-development.js" /* webpackChunkName: "component---src-pages-services-blockchain-game-development-js" */),
  "component---src-pages-services-digital-twin-services-js": () => import("./../../../src/pages/services/digital-twin-services.js" /* webpackChunkName: "component---src-pages-services-digital-twin-services-js" */),
  "component---src-pages-services-game-animation-js": () => import("./../../../src/pages/services/game-animation.js" /* webpackChunkName: "component---src-pages-services-game-animation-js" */),
  "component---src-pages-services-game-art-js": () => import("./../../../src/pages/services/game-art.js" /* webpackChunkName: "component---src-pages-services-game-art-js" */),
  "component---src-pages-services-game-development-js": () => import("./../../../src/pages/services/game-development.js" /* webpackChunkName: "component---src-pages-services-game-development-js" */),
  "component---src-pages-services-game-porting-service-js": () => import("./../../../src/pages/services/game-porting-service.js" /* webpackChunkName: "component---src-pages-services-game-porting-service-js" */),
  "component---src-pages-services-game-testing-company-js": () => import("./../../../src/pages/services/game-testing-company.js" /* webpackChunkName: "component---src-pages-services-game-testing-company-js" */),
  "component---src-pages-services-gamefi-development-company-js": () => import("./../../../src/pages/services/gamefi-development-company.js" /* webpackChunkName: "component---src-pages-services-gamefi-development-company-js" */),
  "component---src-pages-services-hire-apple-vision-pro-developers-js": () => import("./../../../src/pages/services/hire-apple-vision-pro-developers.js" /* webpackChunkName: "component---src-pages-services-hire-apple-vision-pro-developers-js" */),
  "component---src-pages-services-metaverse-development-js": () => import("./../../../src/pages/services/metaverse-development.js" /* webpackChunkName: "component---src-pages-services-metaverse-development-js" */),
  "component---src-pages-services-mixed-reality-game-development-js": () => import("./../../../src/pages/services/mixed-reality-game-development.js" /* webpackChunkName: "component---src-pages-services-mixed-reality-game-development-js" */),
  "component---src-pages-services-mobile-game-development-js": () => import("./../../../src/pages/services/mobile-game-development.js" /* webpackChunkName: "component---src-pages-services-mobile-game-development-js" */),
  "component---src-pages-services-nft-game-development-company-js": () => import("./../../../src/pages/services/nft-game-development-company.js" /* webpackChunkName: "component---src-pages-services-nft-game-development-company-js" */),
  "component---src-pages-services-play-to-earn-game-development-js": () => import("./../../../src/pages/services/play-to-earn-game-development.js" /* webpackChunkName: "component---src-pages-services-play-to-earn-game-development-js" */),
  "component---src-pages-services-slot-game-development-company-js": () => import("./../../../src/pages/services/slot-game-development-company.js" /* webpackChunkName: "component---src-pages-services-slot-game-development-company-js" */),
  "component---src-pages-services-virtual-reality-game-development-company-js": () => import("./../../../src/pages/services/virtual-reality-game-development-company.js" /* webpackChunkName: "component---src-pages-services-virtual-reality-game-development-company-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-staff-augmentation-services-js": () => import("./../../../src/pages/staff-augmentation-services.js" /* webpackChunkName: "component---src-pages-staff-augmentation-services-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

